.whatsapp_float {
    position: fixed;
    bottom: 20px;
    right: 20px;
    z-index: 100;
    cursor: pointer;
    transition: transform 0.3s ease-in-out;
}

.whatsapp_float:hover {
    transform: scale(1.1);
}


.header-div {
    background-image: url("../public/casinoBG.webp");
    background-position: center center;
    background-size: cover;
    color: white;
    height: 100vh;
    background-attachment: fixed;
}

/* new index page style */
.WrapperTransparent {
    background-color: #000000a2;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.frontLeft {
    width: 40vw;
    display: flex;
    justify-content: center;
    align-items: center;
}

.frontLeft .logo img {
    object-fit: cover;
    height: 600px;
}

.frontRight {
    width: 60vw;
    margin: auto 0;
}

/* new index page style end */

.header {
    display: flex;
    min-height: 40vh;
    align-items: center;
}

.header .logo {
    margin: 0 10%;
}

.queries {
    display: flex;
    flex-direction: column;
    /* gap: 40px; */
}

.box {
    margin: 20px 0;
}

.box .btn {
    height: 50px;
    font-weight: 700;
    font-size: large;
    margin: 0 !important;

}

.front-banner {
    display: flex;
    align-items: center;
    height: 60vh;
}

.front-banner-content {
    width: 60vw;
    margin: 0 10%;
}

/* style for all Golden headeings */
.front-banner-content-heading {
    font-size: 4.5rem;
    font-weight: 700;
    background-image: repeating-linear-gradient(to right, #a2682a 0%, #be8c3c 8%, #be8c3c 18%, #d3b15f 27%, #faf0a0 35%, #ffffc2 40%, #faf0a0 50%, #d3b15f 58%, #be8c3c 67%, #b17b32 77%, #bb8332 83%, #d4a245 88%, #e1b453 93%, #a4692a 100%);
    background-size: 150%;
    -webkit-background-clip: text;
    background-clip: text;
    -webkit-text-fill-color: transparent;
    font-size: 80px;
    font-weight: 200;
    font-family: "Yantramanav";
    filter: drop-shadow(0 0 1px rgba(255, 200, 0, .3));
    animation: MoveBackgroundPosition 6s ease-in-out infinite;
}

@keyframes MoveBackgroundPosition {
    0% {
        background-position: 0% 50%
    }

    50% {
        background-position: 100% 50%
    }

    100% {
        background-position: 0% 50%
    }
}

/* Style for Golden buttons */
.golden-btn+.golden-btn {
    margin-top: 1em;
}

.golden-btn {
    display: inline-block;
    outline: none;
    font-family: inherit;
    font-size: 1em;
    box-sizing: border-box;
    border: none;
    border-radius: .3em;
    height: 2.75em;
    line-height: 2.5em;
    text-transform: uppercase;
    padding: 0 1em;
    box-shadow: 0 3px 6px rgba(0, 0, 0, .16), 0 3px 6px rgba(110, 80, 20, .4),
        inset 0 -2px 5px 1px rgba(139, 66, 8, 1),
        inset 0 -1px 1px 3px rgba(250, 227, 133, 1);
    background-image: linear-gradient(160deg, #a54e07, #b47e11, #fef1a2, #bc881b, #a54e07);
    border: 1px solid #a55d07;
    color: rgb(120, 50, 5);
    text-shadow: 0 2px 2px rgba(250, 227, 133, 1);
    cursor: pointer;
    transition: all .2s ease-in-out;
    background-size: 100% 100%;
    background-position: center;
}

.golden-btn:focus,
.golden-btn:hover {
    background-size: 150% 150%;
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.19), 0 6px 6px rgba(0, 0, 0, 0.23),
        inset 0 -2px 5px 1px #b17d10,
        inset 0 -1px 1px 3px rgba(250, 227, 133, 1);
    border: 1px solid rgba(165, 93, 7, .6);
    color: rgba(120, 50, 5, .8);
}

.golden-btn:active {
    box-shadow: 0 3px 6px rgba(0, 0, 0, .16), 0 3px 6px rgba(110, 80, 20, .4),
        inset 0 -2px 5px 1px #b17d10,
        inset 0 -1px 1px 3px rgba(250, 227, 133, 1);
}


/* Below is the normal style */


.front-banner-image {
    width: 35vw;
    height: 100%;
    justify-content: center;
}

.bottom-right-image {
    position: absolute;
    height: 50%;
    bottom: 0;
    right: 20px;
    max-width: 100%;
    max-height: 100%;
    object-fit: cover;
}

.sponser_hed {
    margin: 0 10%;
    padding: 50px 0 !important;
    text-align: center;
}

.sponser-list {
    display: flex;
    flex-wrap: wrap;
    margin: 0 10%;
    justify-content: space-between;
    gap: 20px;
}

.Sponser-div {
    min-height: auto;
    padding-bottom: 30px;
    position: relative;
    z-index: 10;
}

.threeGirl {
    position: absolute;
    bottom: 5%;
    right: 10%;
    z-index: -10;
    width: 800px;
}

.nomior>img {
    margin-right: 1rem;
}

.sponser-list .card-container {
    background: #161516;
    border-radius: 10px;
    overflow: hidden;
    padding: 20px;
    display: flex;
    box-shadow: 0 0 10px rgba(234, 224, 48, 0.653);
    border: 1px solid #dbc716;
    flex-wrap: wrap;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    width: 25%;
    min-width: 300px;
    text-align: center;
    margin-bottom: 40px;
    transition: all .4s ease-in-out;
    opacity: 0.8;
}

.sponser-list .card-container:hover {
    background-color: rgba(56, 48, 5, 0.95);
}

.cardItem-img {
    height: 60px;
    width: 100%;
    object-fit: cover;
    margin: 10px 0;
}

.a_parnter {
    display: inline-block;
    font-size: 20px;
    font-weight: bold;
    color: #fff;
    width: 100%;
    max-width: 220px;
    margin: 30px 0 0 0;
    padding: 18px 10px;
    border: 1px solid #dbc716;
    border-radius: 10px;
    text-align: center;
    box-shadow: 0 0 10px rgba(255, 255, 255, 0.5);
    background: transparent;
    text-decoration: none;
}


.gname {
    font-size: 1.2rem;
    font-weight: 700;
    padding-top: 20px;
}

.whatsappContact {
    display: flex;
    flex-direction: column;
    gap: 20px;
    margin-bottom: 40px;

}

.whatsappContact .whatsappicon {
    margin: 0;
    width: 80%;
}

/* CSS for Partners Modal */


.modal-overlay {
    background-color: rgba(0, 0, 0, 0.5);
}

.modal-content {
    width: auto;
    max-width: calc(100% - 100px);
    /* Adjusted based on content size with 50px padding on each side */
    margin: 50px auto;
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.modal-heading {
    font-size: 1.5rem;
    margin-bottom: 10px;
}

.modal-button {
    background-color: #007bff;
    color: #fff;
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 1rem;
    transition: background-color 0.3s ease;
}

.modal-button:hover {
    background-color: #0056b3;
}


/* CSS for Partners Modal ends here */

.Welcome-div {
    padding: 30px 5%;
    background-color: #000000;
    background-position: center center;
    background-size: cover;
    background-attachment: fixed;
    color: rgb(238, 213, 125);
    padding: 2% 10%;
    margin: 0;
}

.whycontent {
    display: flex;
    justify-content: space-between;
}

.whyhead {
    text-align: center;
}

.whybox {
    width: 50vw;
    display: flex;
    flex-direction: column;
    font-size: 1.2rem;
    list-style: circle;
    font-weight: 500;
    padding: 0 20px;
    justify-content: center;
}

.whyimg {
    width: 40vw;
}

.footer {
    padding: 20px 0px;
    margin: 0;
}

.golden-background {
    background: radial-gradient(ellipse at center, #7d6304 0%, #000000 100%);
}


.Rotor-div {
    display: flex;
    color: white;
    min-height: 70vh;
    align-items: center;
    justify-content: space-around;
    padding: 30px 0px;
}

.Rotor-line {
    width: 200px;
    border-bottom: 5px solid yellow;
}

.Rotor-box1 {
    width: 40vw;
}

.Rotor-box1 .btn {
    font-weight: 700;
    margin: 10px 10px;
}

.Rotor-box2 {
    width: 40vw;
}

.actor-talk {
    height: 600px;
}

.actor-talk img {
    height: 100%;
}


#wheel {
    position: relative;
    max-width: 425px;
}

#wheel-img {
    width: 100%;
    height: 100%;
}

.cust-sup-div {
    background-image: url('../public/casinoBG.webp');
    background-position: center center;
    background-size: cover;
    height: auto;
    color: white;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    background-attachment: fixed;
}

.cust-sup-div .WrapperTransparent {
    height: auto;
    gap: 60px;
}

.cs-box1 {
    width: 50vw;
}

.cs-box2 {
    width: 50vw;
    display: flex;
    flex-direction: column;
    text-align: center;
}

.cs-box1 .whatsappicon {
    height: 60px;
    margin: 0;
    width: 300px;
}

.games-div {
    height: auto;
    color: white;
    text-align: center;
    padding-bottom: 20px;
    display: flex;
    flex-direction: column;
}

.games-div .btn {
    margin: 20px;
    font-weight: 700;
}

.games-heading {
    padding-top: 30px;
}

.games-content {
    font-size: 35px;
    margin: 0 10%;
    padding: 20px 0;
}

.games-message {
    font-weight: 700;
    font-size: larger;
}

.games-line {
    margin: auto;
}

.games-logo-container {
    display: flex;
    justify-content: space-evenly;
    margin: 50px 10%;
}

.games-logo-container .card-container {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    width: 23%;
    text-align: center;
}

.carouselExampleControls {
    display: flex;
    flex-direction: row;
}

.center-image {
    display: block;
    margin: 0 auto;
}

.carosal-heading {
    padding: 4rem;
}

.carosal-heading h1 {
    margin-bottom: 3rem;
}

.instagram-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    padding: 50px 0;
}

.insta-icons {
    width: 240px;
}

.contact-outer-div {
    padding: 100px;
}

.contact-container {
    display: flex;
}

.contact-box1 {
    width: 50vw;
    text-align: center;
}

.contact-box2 {
    width: 50vw;
}

.contact-box2 .btn {
    font-weight: 700;
    margin: 0 10px 10px 0;
}

.contact-content {
    font-size: 20px;
    font-weight: bolder;
    display: flex;
    align-items: center;
}

.whatsappDetails .golden-btn img {
    margin: auto 10px;
}


/* CSS for offers Section */
.offers {
    display: flex;
    background-image: url(../public/singleGirl.webp);
    background-position: center center;
    background-size: cover;
    background-attachment: fixed;
    position: relative;

}

.offersTransparent {
    background-position: center center;
    background-color: #000000da;
    display: flex;
    padding: 20px 10%;
}

.offersLeft {
    display: flex;
    flex-direction: column;
    width: 50%;
    justify-content: center;
}

.offer_list b {
    color: #fdd054;
    margin-right: 3px;
}

.offerspline {
    font-size: 40px;
    font-weight: 400;
    margin-bottom: 10px;
    color: #41cd7d;
    text-align: left;
}

.offerssubtitle {
    color: goldenrod;
    margin-bottom: 20px;
    text-align: left;
}

.offer_list>div {
    display: flex;
    align-items: flex-start;
    justify-content: center;
    flex-direction: column;
}

.offer_list>div span {
    padding: 15px;
    color: white;
    font-weight: 700;
}

.offer_list>div span {
    border: 2px dashed goldenrod;
    border-radius: 20px;
    padding: 20px;
    margin-top: 20px;
    position: relative;
}

.whatsappicon {
    background-color: #ffffff;
    padding: 18px 20px;
    border-radius: 40px;
    font-size: 20px;
    color: #085531;
    display: inline-flex;
    align-items: center;
    transition: 0.4s linear;
    box-shadow: inset 0 0 0 5px #1f2340;
    border: 1px solid #41cd7d;
    text-transform: capitalize;
    margin-top: 40px;
    font-weight: 600;
    text-decoration: none;
    width: 250px;
}

.whatsappicon img {
    padding-right: 10px;
}

.whatsappicon:hover {
    background-color: #41cd7d;
    border: 1px solid white;
    color: white;
}



.offersRight {
    width: 50%;
    display: flex;
    justify-content: center;
}


/* Offers section CSS ends here */
.idSection {
    background-image: url(../public/goldenBGImg.webp);
    background-position: center center;
    background-size: cover;
    background-attachment: fixed;
}

.idTransparentBG {
    display: flex;
    padding: 20px 10%;
    height: 100vh;
    background-color: #000000d1;
}

.idLeft {
    width: 50vw;
    display: flex;
    flex-direction: column;
    justify-content: center;
    color: white;
}

.idpline {
    font-size: 24px;
    font-weight: 400;
    margin-bottom: 10px;
    color: goldenrod;
    text-align: left;
}

.listHed {
    font-size: 38px;
    font-weight: 600;
}

.blist {
    display: inline-block;
    margin-top: 40px;
}

.claimButton {
    color: #000000;
    padding: 18px 20px;
    border-radius: 40px;
    justify-content: center;
    font-size: 20px;
    display: inline-flex;
    align-items: center;
    transition: 0.4s linear;
    box-shadow: inset 0 0 0 5px #1f2340;
    border: 1px solid #cd9e41;
    text-transform: capitalize;
    margin-top: 20px;
    font-weight: 600;
    text-decoration: none;
    width: 290px;
}

.claimButton:hover {
    border: 1px solid white;
    background-color: #d49f2b;
    color: white;
}


.idRight {
    width: 50vw;
    display: flex;
    gap: 40px;
    justify-content: end;
}

/* Info Section CSS starts here */

.infoCards {
    transition: all 0.3s ease-out;
    padding: 40px 20px 36px;
    background-color: rgb(255 255 255 / 10%);
    border-radius: 20px;
    margin-top: 20px;
    font-size: 16px;
    text-align: center;
    backdrop-filter: blur(3px);
    -webkit-backdrop-filter: blur(3px);
    color: white;
    width: 250px;
}

.infoCards:hover {
    background-color: rgba(239, 185, 51, 0.5);
    box-shadow: 0 3px 6px rgba(0, 0, 0, .16), 0 3px 6px rgba(110, 80, 20, .4),
        inset 0 -2px 5px 1px #b17d10,
        inset 0 -1px 1px 3px rgba(250, 227, 133, 1);
}

.icon_box {
    height: 80px;
    width: 80px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 auto 15px;
    border-radius: 100%;
    background-color: #3e0100;
}

.info1 {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    margin: 80px 0;
}

.info2 {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    margin: 80px 0;
}

/* Info section ends here */


/* Responsiveness for Desktop */
@media only screen and (min-width: 1025px) {
    /* Styles for desktop */


    .Rotor-box0 {
        display: none;
    }
}

/* Responsiveness for Tablet */
@media only screen and (min-width: 768px) and (max-width: 1200px) {
    /* Styles for tablet */

    .header{
        height: auto;
        padding-bottom: 100px;
    }
    .header-div{
        height: auto;
    }
    .header-div .WrapperTransparent{
        height: 100%;
        padding-bottom: 100px;
    }

    .Rotor-box0 {
        display: none;
    }




    .cust-sup-div .WrapperTransparent {
        flex-direction: column;
    }

    .cust-sup-div {
        flex-direction: column;
        height: auto;
    }

    .supt_hed {
        padding: 10px 0;
    }

    .cs-box1 {
        width: 100%;
        text-align: center;
        padding: 30px;
    }

    .cs-box1 .Rotor-line {
        width: auto;
    }

    .custbtn .btn {
        width: 100%;
        font-weight: 700;
        height: 50px;
    }

    .cs-box2 {
        display: none;
    }




    .idTransparentBG {
        flex-direction: column;
        height: auto;
    }

    .idLeft {
        width: 100%;
        align-items: center;
    }

    .blist li {
        text-align: center;
        list-style: none;
        padding: 10px;
        font-size: 1.2rem;
    }

    .idRight {
        width: 100%;
        flex-direction: column;
        margin: 20px 0;
        gap: 5px;
    }

    .infoCards {
        width: 45%;
    }

    .info1 {
        flex-direction: row;
        justify-content: space-around;
        margin: 0;
    }

    .info2 {
        flex-direction: row;
        justify-content: space-around;
        margin: 0;
    }



}


/* Responsiveness for Mobile */
@media only screen and (max-width: 767px) {

    /* Styles for mobile */
    .whatsapp_float {
        bottom: 30px;
        right: 15px;
    }

    .header-div {
        height: auto;
        padding-bottom: 0;
    }
    .header-div .WrapperTransparent{
        padding-bottom: 0;
    }
    .WrapperTransparent .frontRight{
        padding-bottom: 0;
    }

    .header {
        display: flex;
        flex-direction: column;
    }

    .logo img {
        height: 200px;
    }

    .box {
        margin: 20px;
        text-align: center;
    }

    .box .btn {
        width: 100%;
        height: 50px;
        margin: 8px;
    }

    .front-banner {
        flex-direction: column;
        padding-bottom: 200px;
    }

    .front-banner-image {
        width: 90vw;
        height: 100%;
        text-align: center;
    }

    .bottom-right-image {
        position: static;
        margin-top: 38px;
        height: 100%;
        width: auto;
        object-fit: contain;
    }

    .front-banner-content {
        width: 100%;
        text-align: center;
    }

    .front-banner-content-heading {
        font-size: 2.5rem;
        font-weight: 700;
        text-align: center;
    }

    .Sponser-div {
        min-height: auto;
    }
   

    .sponser_hed {
        padding: 50px 0 !important;
        margin: 0;
    }

    .sponser-list {
        flex-direction: column;
        align-items: center;
        margin: 0;
        height: auto;
    }

    .sponser-list .card-container {
        width: 70%;
        min-width: 150px;
        display: flex;
        justify-content: center;
        align-items: center;

    }

    .whycontent {
        display: flex;
        flex-direction: column;
    }

    .whyimg {
        display: none;
    }

    .whybox {
        font-size: 1rem;
        list-style: disc;
        margin: 20px 0;
    }

    .Rotor-div {
        flex-direction: column;
        height: auto;
    }

    .rotor_hed {
        width: 100% !important;
        font-weight: 700 !important;
    }

    .Rotor-box0 {
        padding-bottom: 30px;
        width: 70%;
    }

    .Rotor-box1 {
        width: 100%;
        text-align: center;
        align-items: center;
        padding: 0 10%;

    }

    .Rotor-box1 h3 {
        font-size: large;
        padding: 20px 0;
    }

    .Rotor-box1 .claimButton {
        width: 100%;
        font-size: small;
        font-weight: 700;
        height: auto;
    }

    .Rotor-box1 .claimButton span {
        width: 100%;
    }

    .Rotor-box2 {
        display: none;
    }

    .Rotor-line {
        margin: auto;
        width: auto;
    }

    .rotortext {
        font-size: 1.5rem;
    }

    .Rotor-box1 .btn {
        display: block;
        width: 90%;
        font-size: small;
        font-weight: 700;
        min-height: 50px;
        max-height: auto;
        margin: auto;
    }

    .cust-sup-div {
        flex-direction: column;
        height: auto;
    }

    .supt_hed {
        padding: 10px 0;
    }

    .cs-box1 {
        width: 100%;
        text-align: center;
        padding: 30px;
    }

    .cs-box1 .whatsappicon {
        width: 70%;
        font-size: small;
    }

    .cs-box1 .whatsappicon img {
        width: 40px;
    }

    .custbtn .btn {
        width: 100%;
        font-weight: 700;
        height: 50px;
    }

    .cs-box2 {
        width: 90%;
        text-align: center;
    }

    .cs-box2 img {
        width: 80%;
    }

    .games-div {
        height: auto;
    }

    .gamesContent .claimButton {
        width: 80% !important;
        height: auto !important;
        font-size: small;
        font-weight: 700;
        margin: 20px 0;
    }

    .gamesContent {
        justify-content: center;
    }

    .gamesContent .btn {
        display: block;
        width: 80%;
        font-size: small;
        font-weight: 700;
        height: 50px;
        margin: 10px auto;
    }

    .games-div h2 {
        padding: 20px 0;
    }

    .games-logo-container {
        flex-direction: column;
        align-items: center;
        gap: 60px;
    }

    .games-logo-container .card-container {
        width: 80%;
        width: 70%;
        min-width: 150px;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .games-content {
        font-size: large;
        padding: 5px 0;
        margin: 0 5%;
    }

    .center-image {
        width: 100%
    }

    .whybox {
        flex-direction: column;
        width: 100%;
    }

    .whybox li {
        width: 100%;
    }

    .wbox {
        width: 100%;
        list-style: circle;
    }

    .instagram-container {
        flex-direction: column;
        align-items: center;
        padding: 40px 0;
        gap: 20px;
    }

    .insta-icons {
        text-align: center;
        width: 80vw;
        height: auto;
        border: 3px solid goldenrod;
        background-color: black;
    }

    .contact-container {
        flex-direction: column;
        align-items: center;
    }

    .contact-outer-div {
        padding: 10px;
    }

    .contact-box1 {
        width: 100%;
    }

    .contact-box1 img {
        width: 200px;
    }
    .nomior img{
        width: 25px;
    }

    .contact-box2 {
        width: 100%;
        text-align: center;
        align-items: center;
    }

    .contact-content {
        justify-content: center;
        padding: 20px 0;
    }

    .contact-box2 .btn {
        display: block;
        width: 90%;
        font-weight: 700;
        height: 50px;
        margin: 10px auto;
    }

    .whatsappContact {
        display: flex;
        flex-direction: column;
    }



    .WrapperTransparent {
        background-color: #00000085;
        width: 100%;
        height: auto !important;
        display: flex;
        flex-direction: column;

    }

    .frontLeft {
        width: 100vw;
        height: 55vh;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 10px;
    }

    .frontLeft .logo img {
        object-fit: contain;
        width: 35vh;
        height: auto;
    }

    .frontRight {
        width: 100vw;
        margin: 0 0;
        padding: 20px;
        text-align: center;
    }


    .offers {
        display: flex;
        flex-direction: column;
        height: auto;
        position: relative;
    }

    .offersTransparent {
        padding: 50px 5%;
    }

    .offersLeft {
        width: 100%;
        text-align: center;
    }

    .offerssubtitle {
        text-align: center;
        margin: 20px 0;
        font-size: 40px;
    }

    .offer_list>div {
        align-items: center;
    }

    .offer_list>div span {
        width: 100%;
    }

    .whatsappicon {
        margin: 30px auto;
        width: 100%;
        justify-content: center;
    }

    .offersRight {
        display: none;
    }



    .idSection {
        background-position: center;
    }

    .idTransparentBG {
        flex-direction: column;
        height: auto;
    }

    .idLeft {
        width: 100%;
        align-items: center;
        text-align: center;
    }

    .idpline {
        text-align: center;
    }

    .blist {
        margin-left: 0;
    }

    .blist li {
        text-align: center;
        list-style: none;
        padding: 10px;
        font-size: 1.2rem;
    }

    .idRight {
        width: 100%;
        flex-direction: column;
        margin: 20px 0;
        gap: 5px;
    }

    .infoCards {
        width: 100%;
    }

    .info1 {
        flex-direction: column;
        margin: 0;
    }

    .info2 {
        flex-direction: column;
        margin: 0;
    }

    .claimButton {
        width: 100%;
        font-size: medium;
    }


}
